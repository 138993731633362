<template>
    <li @mouseenter="mouseIn" @mouseleave="mouseOut">
        <hr class="divider" v-if="hasDivider" />

        <PlatonLink
            :aria-expanded="`${expanded}`"
            @click.native="expanded = !expanded"
            class="waves-effect cursor-pointer d-flex align-items-center"
            :link="menuLink"
            v-bind="extraProps"
            :class="[`menu-level-${level}`, { active: isActive, 'no-icon': icon }]"
        >
            <PlatonLink
                v-if="icon && isAdmin && isMyProject(item)"
                class="small"
                tabindex="-1"
                :stop-propagation="true"
                :link="`forms/menus/${item.id}?_target=modal`"
            >
                <i :class="icon" class="menu-icon" />
            </PlatonLink>

            <i v-else :class="icon" class="menu-icon" />

            <span class="flex-grow-1 menu-item-text" v-html="text" />

            <div class="right-align">
                <b-badge :variant="badgeType" class="px-2 mt-0 ml-2 float-right" v-html="item.badge" />

                <span class="menu-plus" v-if="hasChildren" style="z-index: 55">
                    <i :class="{ rotated: expanded }" class="fa fa-chevron-down" />
                </span>
            </div>
        </PlatonLink>

        <transition name="fade">
            <ul
                class="nav-second-level mm-collapse"
                :class="{ 'mm-show': expanded }"
                :ref="`subMenu_${item.id}`"
                v-show="expanded"
                v-if="(isGroup && hasChildren) || (menuCollapsed && level === 1)"
            >
                <div class="font-weight-bold px-3 py-2" v-show="menuCollapsed" v-html="text" />

                <MenuItem :level="level + 1" :item="m" :key="`${m.projectId}_${m.id}`" v-for="m in item.children" />
            </ul>
        </transition>
    </li>
</template>

<script>
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import { mapGetters } from "vuex"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import { appendSlash } from "@Platon/core/helpers"
import ParametrizedString from "@Platon/core/ParametrizedString"

export default {
    name: "MenuItem",
    components: { PlatonLink },

    mixins: [PermissionMixin],

    props: {
        /** @type PlatonMenu */
        item: {},
        level: {
            type: Number,
            default: 1
        },
        disableDivider: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            expanded: false
        }
    },

    mounted() {
        this.$on("initActiveMenu", () => {
            this.$parent.$emit("initActiveMenu")

            this.expanded = true
        })

        this.tryToAutoExpand()
    },

    methods: {
        mouseIn(e) {
            if (!this.menuCollapsed) return

            if (this.level === 1) this.expanded = true
            this.expanded = true
            let element = this.$refs["subMenu_" + this.item.id]
            if (this.level === 1 && element) {
                element.style.top = `${e.clientY - e.offsetY}px`
            } else if (element) {
                element.style.top = `${e.clientY - e.offsetY}px`
                element.style.left = `${e.clientX - e.offsetX + e.clientY - 125}px`
            }
        },

        mouseOut() {
            if (!this.menuCollapsed) return

            this.expanded = false
        },

        tryToAutoExpand() {
            this.$nextTick(() => {
                if (this.isActive && !this.menuCollapsed) {
                    this.$parent.$emit("initActiveMenu")
                }
            })
        }
    },

    computed: {
        ...mapGetters({
            menuCollapsed: "platon/menuCollapsed"
        }),

        extraProps() {
            let props = {}

            if (this.item.cssStyle) {
                props.style = this.item.cssStyle
            }

            return props
        },

        isActive() {
            if (this.$route.query._mid) {
                return this.item.id == this.$route.query._mid
            }

            if (!this.item.link || (this.item.link && this.item.link.length < 5)) {
                return false
            }

            const { route: routeOfLink } = this.$router.resolve(appendSlash(this.item.link))

            const arePathParamsEqual = Object.keys(routeOfLink.params).every((key) => {
                return routeOfLink.params[key] === this.$route.params[key]
            })

            const areQueryParamsEqual = Object.keys(routeOfLink.query).every((key) => {
                // if key starts with _, we just skip it, because they are private (interval) vars
                if (key.startsWith("_")) {
                    return true
                }

                return routeOfLink.query[key] === this.$route.query[key]
            })

            return routeOfLink.name === this.$route.name && arePathParamsEqual && areQueryParamsEqual
        },

        isGroup() {
            return this.hasChildren
        },

        // only one link
        isLink() {
            return !this.hasChildren
        },

        hasDivider() {
            return !!this.item.hasDividerBefore && !this.disableDivider
        },

        hasChildren() {
            return Array.isArray(this.item.children) && this.item.children.length > 0
        },

        badgeType() {
            return this.item.badgeType || "primary"
        },

        text() {
            return this.item.name
        },

        icon() {
            return this.item.cssClass || "fa fa-info"
        },

        menuLink() {
            /**
             * @type {string}
             */
            let link = this.item.link

            if (!link || typeof link !== "string") {
                return "#"
            }

            if (link.match(/^(http|https):\/\//gi)) {
                return link
            }

            let menuRoute = this.$router.resolve(appendSlash(ParametrizedString.replace(link)))

            if (this.item.isTrackMenu) {
                let newRoute = menuRoute.route
                newRoute.query["_mid"] = this.item.id

                menuRoute = this.$router.resolve(newRoute)
            }

            return menuRoute.route.fullPath
        }
    },

    watch: {
        "$store.state.platon.menuCollapsed"(val) {
            if (this.expanded && val) {
                this.expanded = false
            }

            if (!val) {
                this.tryToAutoExpand()
            }
        }
    }
}
</script>
