<template>
    <div class="xModalViewPlaceholder" v-show="isOpen">
        <div class="xModalViewPlaceholder__context">
            <div class="xModalView" :id="`xModalView-provider-frame${useId}`">
                <Container class="xModalView__navbar">
                    <h3 class="xModalView__navbar--header">
                        {{ header }}
                    </h3>
                    <div class="xModalView__navbar--close">
                        <button class="xModal-close-button" @click="onCloseModalWindow">
                            <i class="mdi mdi-close" />
                        </button>
                    </div>
                </Container>
                <div class="xModalView__frame">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Container from "./container"
import { makeAnId } from "../utils"

export default {
    name: "xModal",
    components: { Container },
    props: {
        header: {
            type: String,
            default: ""
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        styles: {
            type: Object,
            default: function () {
                return {
                    height: 500,
                    width: window.innerWidth < 768 ? window.innerWidth - 40 : 600
                }
            }
        }
    },
    data() {
        return {
            useId: makeAnId()
        }
    },
    methods: {
        onCloseModalWindow() {
            this.$emit("on-close")
        }
    },
    mounted() {
        const xModalProviderFrame = document.querySelector("#xModalView-provider-frame" + this.useId)
        xModalProviderFrame.style.height = this.styles.height + "px"
        xModalProviderFrame.style.width = this.styles.width + "px"
    }
}
</script>

<style lang="scss" scoped>
@import "./utils/mixins.scss";

.xModalViewPlaceholder {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 999;

    &__context {
        height: 100%;
        width: 100%;
        position: relative;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.xModalView {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    background-color: #fff;
    border: 1.5px solid rgba($color: $df-color, $alpha: 0.4);
    border-radius: $df-border-radius;
    // box-shadow: 3px 3px 10px 0px rgba($color: $df-color, $alpha: 0.3);
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);

    &__navbar {
        border-top-left-radius: $df-border-radius;
        border-top-right-radius: $df-border-radius;
        @include display-flex;
        justify-content: space-between;

        &--header {
            color: $df-color;
            font-weight: $df-w-bold;
            font-size: calc($df-size - 7px);
            margin-bottom: 0;
        }

        &--close {
            height: 40px;
            width: 40px;
            background-color: #fff;
            @include display-flex-center;

            & button {
                width: 35px;
                height: 35px;
                border: none;
                border-radius: 50%;
                background-color: #fff;

                @include display-flex-center;

                & i {
                    color: $df-color;
                    font-size: calc($df-size - 2px);
                }
            }
        }
    }

    &__frame {
        height: 100%;
        padding: 20px 0px;
        border-bottom-left-radius: $df-border-radius;
        border-bottom-right-radius: $df-border-radius;
    }
}
</style>
