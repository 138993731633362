<template>
    <div class="AccountMenu">
        <div class="AccountMenu--profile">
            <img
                alt="Account Menu Profile Image"
                src="https://img.myloview.com/stickers/default-avatar-profile-flat-icon-social-media-user-vector-portrait-of-unknown-a-human-image-700-209987471.jpg"
                class="AccountMenu--profile__image"
            />
        </div>
        <b-avatar :src="$platonFile($user.photo)" size="48px" :text="$user.username[0]" variant="primary" />
        <b-dropdown variant="link" :text="$l('platon.profile', 'Профиль')" right>
            <b-dropdown-item href="#" class="AccountMenu--item">
                <platon-link link="/system/profile" class="topbar-btn primary-text text-decoration-none">
                    <i class="mdi mdi-cog" />
                    {{ $l("platon.settings", "Созламалар") }}
                </platon-link>
            </b-dropdown-item>
            <b-dropdown-item href="#" class="AccountMenu--item" @click="openUserAuth">
                <i class="mdi mdi-account" />
                <span class="auth-link">{{ $l("platon.user-security", "Фойдаланувчи хавфсизлиги") }}</span>
            </b-dropdown-item>
            <b-dropdown-item href="#" class="AccountMenu--item">
                <platon-link link="/system/logout" class="topbar-btn primary-text text-decoration-none">
                    <i class="fa fa-sign-out-alt mr-1" />
                    {{ $l("platon.logout", "Чиқиш") }}
                </platon-link>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import DropdownItem from "../extended/DropdownItem.vue"
import { IS_PROD, LOCAL_AUTH_URL } from "@Platon/const"

export default {
    name: "AccountMenu",
    components: [DropdownItem],
    methods: {
        openUserAuth() {
            const path = "/auth/user"
            if (IS_PROD) {
                window.location.replace(window.location.origin + path)
            } else {
                window.location.replace(LOCAL_AUTH_URL + path)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.AccountMenu {
    display: flex;
    align-items: center;

    & .b-avatar {
        height: 32px !important;
        width: 32px !important;
    }
    & .b-avatar-text {
        font-size: 14px !important;
    }

    &--profile {
        display: none;
        visibility: hidden;
        height: 28px;
        width: 28px;

        &__image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &--item {
        & .dropdown-item {
            width: 100%;
            padding: 0 !important;
        }

        & i {
            margin-right: 8px;
        }
    }
}
</style>
