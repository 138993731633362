<template>
    <Modal :header-class="options.headerClass" :footer-actions="actions" :slot-class="options.slotClass">
        <template #title>
            <span style="font-size: 18px">{{ title }}</span>
        </template>
        <component v-if="displayComponent !== null" :is="displayComponent" />
        <div v-else v-html="text" />
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal.vue"
import ModalCloseOnEscMixin from "@Platon/mixins/ModalCloseOnEscMixin"
import { $language } from "@Platon/core/translations"

export default {
    name: "MessageDialog",
    components: { Modal },

    mixins: [ModalCloseOnEscMixin],

    props: {
        title: {
            type: String,
            default: () => $language("platon.message_dialog_title", "Хабар")
        },

        options: {},

        text: {},

        actions: {
            type: Array,
            default: () => [{ text: $language("platon.ok", "Ок") }]
        }
    },

    computed: {
        displayComponent() {
            if (typeof this.$props.text === "object" && this.$props.text !== null) {
                return this.$props.text
            }

            return null
        }
    }
}
</script>

<style></style>
