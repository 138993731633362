<template>
    <th ref="cell">
        <div v-if="isAdmin && isMyProject(column) && column.headerComponent">
            <PlatonLink :link="`/forms/table_columns/${column.id}?_target=modal`">
                <i class="fa fa-cog"></i>
            </PlatonLink>

            <component :is="column.headerComponent" />
        </div>

        <PlatonLink
            v-else-if="isAdmin && isMyProject(column) && hasPermForForm"
            :link="`/forms/table_columns/${column.id}?_target=modal`"
        >
            <span v-html="column.title" />
        </PlatonLink>

        <component v-else-if="column.headerComponent" :is="column.headerComponent" />

        <span v-else v-html="column.title" />

        <span v-if="isSortable" @click="$emit('sort')" class="ml-1">
            <i v-if="column.__sortDirection === 1" class="mdi mdi-sort-ascending"></i>
            <i v-else-if="column.__sortDirection === -1" class="mdi mdi-sort-descending"></i>
            <i v-else class="mdi mdi-sort"></i>
        </span>
    </th>
</template>

<script>
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import { PlatonTableNames } from "@Platon/const"

export default {
    name: "TableHeaderCell",
    components: { PlatonLink },
    mixins: [PermissionMixin],

    props: {
        /** @type TableColumn */
        column: {},
        td: {}
    },

    mounted() {
        if (this.$attrs.clone) {
            this.$nextTick(this.fixBounds)
            window.addEventListener("resize", this.redraw)
        }

        if (this.isAdmin && this.isMyProject(this.column)) {
            this.$el.addEventListener("mouseenter", this.hover)
        }
    },

    beforeDestroy() {
        if (this.$attrs.clone) {
            window.removeEventListener("resize", this.redraw)
        }

        if (this.isAdmin && this.isMyProject(this.column)) {
            this.$el.removeEventListener("mouseenter", this.hover)
        }
    },

    inject: {
        table: {
            default: undefined
        }
    },

    computed: {
        isSortable() {
            return (
                this.column.dataField &&
                this.column.__bottomColumn &&
                this.column.attributes &&
                this.column.attributes.sortable
            )
        },
        hasPermForForm() {
            return !PlatonTableNames.includes(this.$parent.tableName)
        }
    },

    methods: {
        hover() {
            let tooltipData = this.getTooltip()

            if (tooltipData) {
                this.$el.addEventListener("mouseleave", this.mouseLeave)
                this.$sendEvent("PLATON:TOOLTIP:SHOW", this.$el, tooltipData)
            }
        },

        mouseLeave() {
            this.$el.removeEventListener("mouseleave", this.mouseLeave)
            this.$sendEvent("PLATON:TOOLTIP:HIDE", this.$el)
        },

        getTooltip() {
            let text = `устунни созлаш<br>`

            if (!this.column.dataField) {
                text += `${this.column.sortOrder}`
            } else {
                text += `<b>${this.column.dataField}</b> - ${this.column.sortOrder}`
            }

            return {
                text,
                target: this.$el
            }
        },

        redraw() {
            this.$nextTick(this.fixBounds)
        },

        fixBounds() {
            if (!this.table) return

            let original = this.table.$el.querySelector(`th[data-col-id='column-${this.column.id}']`)

            if (!original) return

            let rect = original.getBoundingClientRect()

            if (rect.height !== 0 && rect.width !== 0) {
                this.$el.style.height = rect.height + "px"
                this.$el.style.width = rect.width + "px"
            }
        }
    }
}
</script>

<style></style>
