<template>
    <div class="videoPreview__holder">
        <b-overlay
            :show="isVideoLoading"
            rounded
            opacity="0.8"
            spinner-big
            spinner-variant="primary"
            class="d-inline-block"
        >
            <video class="videoPreview" ref="videoPlayer" controls></video>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name: "VideoPreview",
    props: {
        url: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            isVideoLoading: false
        }
    },
    mounted() {
        this.downloadAndSetVideo()
    },
    methods: {
        downloadAndSetVideo() {
            this.isVideoLoading = true
            this.$api
                .get(this.url, { responseType: "blob" })
                .then((response) => {
                    if (response.status >= 200 && response.status <= 400) {
                        const videoUrl = URL.createObjectURL(response.data)
                        this.setVideoSource(videoUrl)
                    } else {
                        throw new Error(`Video download failed with status ${response.status}`)
                    }
                })
                .catch((error) => {
                    console.error("Video download error:", error)
                })
                .finally(() => {
                    this.isVideoLoading = false
                })
        },
        setVideoSource(videoUrl) {
            this.$refs.videoPlayer.src = videoUrl
            this.$refs.videoPlayer.play()
        }
    }
}
</script>

<style lang="scss" scoped>
.videoPreview {
    min-width: 600px;
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;

    &__holder {
        text-align: center;
        background: #000;
        width: 100%;
    }
}
@media (max-width: 768px) {
    .videoPreview {
        min-width: 80%;
    }
}
</style>
