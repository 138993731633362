<template>
    <div>
        <page-loading-view />
    </div>
</template>

<script>
import PageLoadingView from "@Platon/components/pages/PageLoadingView"
import { IS_PROD, LOCAL_AUTH_URL } from "@Platon/const"

export default {
    name: "LoginPage",

    components: { PageLoadingView },

    created() {
        const path = "/auth/user"
        if (IS_PROD) {
            window.location.replace(window.location.origin + path)
        } else {
            window.location.replace(LOCAL_AUTH_URL + path)
        }
    }
}
</script>
