<template>
    <div class="ProfileViewFragment">
        <section class="ProfileView">
            <ProfileDetails />
            <Divider />
            <Container v-show="false">
                <div class="ProfileView__gawp--permissions">
                    <div class="permission-header">
                        <h3 class="permission-header__typography">{{ $l("platon.permissions", "Рухсатлар") }}:</h3>
                    </div>
                    <ul class="permissions-list">
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                        <li class="permissions-list__name">
                            <code>user.settings.themes.GoldPremiumTheme</code>
                        </li>
                    </ul>
                </div>
            </Container>
        </section>
        <!-- PROFILE SETTINGS SECTION -->
        <section class="ProfileViewSettings">
            <div class="ProfileViewSettings__header">
                <h3 class="ProfileViewSettings__header--typography">{{ $l("platon.security-title", "Хавфсизлик") }}</h3>
            </div>
            <Container class="ProfileViewSettings__details">
                <Accordion
                    icon="mdi mdi-lock-reset"
                    :header="$l('platon.change-password-title', 'Парольни ўзгартириш')"
                >
                    <PasswordSettingsSection />
                </Accordion>
                <Accordion icon="mdi mdi-shield-edit" :header="$l('platon.otp-enable', 'OTP-ни ёқиш')">
                    <MFASection />
                </Accordion>
                <Accordion icon="mdi mdi-key" header="E-imzo">
                    <!-- Currently Accordion has been disabled -->
                    <SignatureSection />
                </Accordion>
            </Container>
        </section>
    </div>
</template>

<script>
import Container from "./components/container"
import Divider from "./components/divider"
import Accordion from "./components/accordion"
import PasswordSettingsSection from "./sections/PasswordSettingsSection"
import SignatureSection from "./sections/SignatureSection"
import MFASection from "./sections/MFASection"
import ProfileDetails from "./sections/ProfileDetails"

import ModalVerification from "./components/xModal"

export default {
    name: "ProfileView",
    props: {},
    components: {
        Container,
        Divider,
        Accordion,
        PasswordSettingsSection,
        MFASection,
        ProfileDetails,
        SignatureSection,

        // #temp
        ModalVerification
    },
    mounted() {
        // this.$modal.show(
        //     Modal,
        //     { width: "400px", clickToClose: false }
        // )
    }
}
</script>

<style lang="scss" scoped>
@import "./profileDetails.scss";
</style>
