import { API_BASE_URL } from "@Platon/const"
export default function (userId) {
	return new Promise((resolve, reject) => {
		try {
			const data = this.$api.get("/user-refresh", {
				baseURL: API_BASE_URL,
				param: { userId }
			})
			resolve(null, data)
		} catch (err) {
			reject(err)
		}
	})
}
